

































import {TRoutes} from '@/shared/enums/routes.enum';
import {ProductTopologyInterface} from '@/shared/interfaces/store/homepage/product-topology.interface';
import {HomepageStore} from '@/store/modules/homepage';
import Logger from 'js-logger';
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class HomeProductTopology extends Vue {

  get productTopology(): ProductTopologyInterface {
    return HomepageStore.productTopology;
  }

  navigateToProductLine(): void {
    this.$router.push({name: TRoutes.ProductLine})
      .catch((e: Error) => Logger.info(e.message));
  }

}
